<template>
  <div v-resize="onResize">
    <general-carousel
      :show-arrows="false"
      :hide-delimiters="false"
      :items-list="bannerImageList"
      cycle
    >
      <template #carousel-item="slotProps">
        <nuxt-link :to="useLocalePrefixForHref((slotProps.item as BannerItem).url)">
          <v-img
            :lazy-src="(slotProps.item as BannerItem).imageUrl"
            :src="(slotProps.item as BannerItem).imageUrl"
            class="rounded-lg"
            alt="banner"
          />
        </nuxt-link>
      </template>
    </general-carousel>
    <j-page-games-game-filter
      ref="homepageGameFilter"
      class="pt-lg-2"
      :game-type="GAMES_TYPES.homepageSearch"
      :loading="loadingHomepageSearchGames"
      @input-event="gameFilterInputHandler"
      @update:loading="loadingHomepageSearchGames = $event"
    />

    <template v-if="showSearchResult">
      <!--Homepage Search Games-->
      <j-dynamic-content-infinite-scroll
        class="mt-8 mt-lg-10"
        :items="getHomepageSearchGames.items"
        :total="getHomepageSearchGames.total"
        :mode="infiniteScrollMode"
        :no-items-text="$t('general_game_not_found')"
        :loading="loadingHomepageSearchGames"
        :remote-storage-host="getAllGamesRemoteStorageHost"
        public-img-store-dir-name="games"
        :load-content="loadHomepageSearchGames"
        @update:mode="infiniteScrollMode = $event"
      />
    </template>
    <template v-else>
      <!--New Games-->
      <j-dynamic-content-carousel
        ref="newGamesCarousel"
        class="mt-8 mt-lg-10"
        :items="newGamesCarouselItems"
        :remote-storage-host="getNewGamesRemoteStorageHost"
        :title="$t('general_new_game')"
        :no-items-text="$t('general_new_game_not_found')"
        :loading="loadingNewGames"
        :lazy-loading="isNewGamesLazyLoading"
        :show-more-btn-link="
          `/new-games/${GAME_PROVIDERS.all}/${GAME_CATEGORIES.all}`.toLowerCase()
        "
        public-img-store-dir-name="games"
        :column-count="columnCountGames"
        :column-gap="columnGapGames"
        :chunk-size="newGamesChunkSize"
        @go-to-next-carousel-item="loadNewGames"
      />

      <!--All Games-->
      <j-dynamic-content-carousel
        ref="allGamesCarousel"
        class="mt-5 mt-lg-15 pt-5"
        :items="allGamesCarouselItems"
        :items-promo="getPromoTiles"
        :remote-storage-host="getAllGamesRemoteStorageHost"
        :title="$t('general_all_games')"
        :no-items-text="$t('general_games_not_found')"
        :loading="loadingAllGames"
        :lazy-loading="isAllGamesLazyLoading"
        :show-more-btn-link="
          `/games/${GAME_PROVIDERS.all}/${GAME_CATEGORIES.all}`.toLowerCase()
        "
        public-img-store-dir-name="games"
        :column-count="columnCountGames"
        :column-gap="columnGapGames"
        :chunk-size="allGamesChunkSize"
        @go-to-next-carousel-item="loadAllGames"
      />
    </template>

    <!--News-->
    <j-dynamic-content-carousel
      class="mt-5 mt-lg-15 pt-5"
      :items="newsCarouselItems"
      :remote-storage-host="`${useBaseAppUrl().api}${API_PREFIX.news}`"
      :title="$t('general_news')"
      :no-items-text="$t('general_news_not_found')"
      :loading="loadingNews"
      :column-count="columnCountNews"
      :column-gap="columnGapNews"
      show-more-btn-link="/news"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useHomePageStore } from '~/stores/homepage';
import { useNewsStore } from '~/stores/news';
import { useGamesStore } from '~/stores/games';
import { useLayoutStore } from '~/stores/layout';
import { API_PREFIX } from '~/constants/apiConfiguration';
import {
  PLATFORM,
  GAMES_TYPES,
  GAME_CATEGORIES,
  GAME_PROVIDERS,
} from '~/constants/general';
import {
  GAMES_SLIDER_CONFIG,
  NEWS_SLIDER_CONFIG,
} from '~/constants/contentItemsGridConfigs';
import type {
  GameFilterInstance,
  DynamicContentCarouselInstance,
} from '~/types/pages/homepage';
import type { GameItem } from '~/types/general/game';
import type { NewsItem } from '~/types/general/news';
import type { BannerItem } from '~/types/pages/homepage';
import type { Mode } from '~/types/components/General/DynamicContentInfiniteScroll';

definePageMeta({
  middleware: [
    'seo-crawler-detector',
    'ensure-canonical-available',
  ],
});
const { t } = useI18n();

usePageMeta(t('homepage_page_title', { separator: '|' }), t('homepage_page_description'));
const nuxtApp = useNuxtApp();
const homepageGameFilter = ref<GameFilterInstance>(null);
const newGamesCarousel = ref<DynamicContentCarouselInstance>(null);
const allGamesCarousel = ref<DynamicContentCarouselInstance>(null);
const homePageStore = useHomePageStore();
const newsStore = useNewsStore();
const gamesStore = useGamesStore();
const layoutStore = useLayoutStore();
const { setPageLoader } = layoutStore;
const {
  getBannerImages,
  getPromoTiles,
} = storeToRefs(homePageStore);
const { getNewsData } = storeToRefs(newsStore);
const {
  fetchBannersData,
  fetchBannersDataForSSR,
  fetchPromoTilesData,
  fetchPromoTilesDataForSSR,
} = homePageStore;
const { fetchNewsDataForSSR } = newsStore;
const {
  getHomepageSearchGames,
  getNewGames,
  getNewGamesRemoteStorageHost,
  getAllGames,
  getAllGamesRemoteStorageHost,
  getIsSeoCrawlerDetected,
} = storeToRefs(gamesStore);
const {
  fetchGamesByType,
  clearHomepageSearchGamesData,
} = gamesStore;
const generateCarouselItems = (
  contentItems: GameItem[] | NewsItem[],
  chunkSize: number,
) => {
  const carouselItems = [];

  for (let i = 0; i < contentItems?.length; i += chunkSize) {
    carouselItems.push(contentItems.slice(i, i + chunkSize));
  }

  return carouselItems;
};
const newGamesCarouselItems = computed(() =>
  generateCarouselItems(getNewGames.value.items, newGamesChunkSize.value),
);
const allGamesCarouselItems = computed(() =>
  generateCarouselItems(getAllGames.value.items, allGamesChunkSize.value),
);
const newsCarouselItems = computed(() =>
  generateCarouselItems(getNewsData.value, newsChunkSize.value),
);
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const currentPlatform = computed(() =>
  isMobileMode?.value
    ? PLATFORM.mobile
    : PLATFORM.desktop,
);
const previousPlatform = ref(currentPlatform.value);
const columnCountGames = useColumnCount(GAMES_SLIDER_CONFIG, ['isXsDisplay', 'isMobileMode']);
const columnGapGames = useContentItemsGridConfig(GAMES_SLIDER_CONFIG).gap;
const columnCountNews = useColumnCount(NEWS_SLIDER_CONFIG, ['isMobileMode']);
const columnGapNews = useContentItemsGridConfig(NEWS_SLIDER_CONFIG).gap;
const newGamesChunkSize = useChunkSize(GAMES_SLIDER_CONFIG, ['isXsDisplay', 'isMobileMode'], 'newGamesChunkSize');
const allGamesChunkSize = useChunkSize(GAMES_SLIDER_CONFIG, ['isXsDisplay', 'isMobileMode'], 'allGamesChunkSize');
const newsChunkSize = useChunkSize(NEWS_SLIDER_CONFIG, ['isMobileMode']);
const bannerImageList = computed(() => getBannerImages.value);
const loadingHomepageSearchGames = ref(false);
const loadingNewGames = ref(false);
const loadingAllGames = ref(false);
const loadingNews = ref(false);
const showSearchResult = ref(false);
const infiniteScrollMode = ref<Mode>('manual');
const gameFilterInputHandler = async (event: boolean) => {
  showSearchResult.value = event;
  infiniteScrollMode.value = 'manual';

  if (!showSearchResult.value && getHomepageSearchGames.value.items.length) {
    clearHomepageSearchGamesData();
  }
};
const loadHomepageSearchGames = async () => {
  await homepageGameFilter.value?.loadGames(true);
};
const loadNewGames = async (
  activeNewGamesCarouselSlide = 0,
  resetCarousel = false,
) => {
  const fetchNewGamesParams = {
    limit: activeNewGamesCarouselSlide
      ? newGamesChunkSize.value
      : newGamesChunkSize.value + newGamesChunkSize.value,
    offset: !activeNewGamesCarouselSlide
      ? 0
      : activeNewGamesCarouselSlide *
        (newGamesChunkSize.value + newGamesChunkSize.value),
    platform: currentPlatform.value,
    gameCategory: GAME_CATEGORIES.all,
    gameProvider: GAME_PROVIDERS.all,
  };
  let appendFetchedGames = true;

  if (resetCarousel) {
    newGamesCarousel.value?.reset();
    appendFetchedGames = false;
  }
  loadingNewGames.value = true;
  try {
    await fetchGamesByType(
      fetchNewGamesParams,
      GAMES_TYPES.new,
      appendFetchedGames,
    );
  } finally {
    loadingNewGames.value = false;
  }
};
const { loadGamesSSR: loadNewGamesForSSR } =
  useFetchGamesSSR(GAMES_TYPES.new, newGamesChunkSize.value + newGamesChunkSize.value);
const loadAllGames = async (
  activeAllGamesCarouselSlide = 0,
  resetCarousel = false,
) => {
  const fetchAllGamesParams = {
    limit: activeAllGamesCarouselSlide
      ? allGamesChunkSize.value
      : allGamesChunkSize.value + allGamesChunkSize.value,
    offset: !activeAllGamesCarouselSlide
      ? 0
      : activeAllGamesCarouselSlide *
        (allGamesChunkSize.value + allGamesChunkSize.value),
    platform: currentPlatform.value,
    gameCategory: GAME_CATEGORIES.all,
    gameProvider: GAME_PROVIDERS.all,
  };
  let appendFetchedGames = true;

  if (resetCarousel) {
    allGamesCarousel.value?.reset();
    appendFetchedGames = false;
  }
  loadingAllGames.value = true;
  try {
    await fetchGamesByType(
      fetchAllGamesParams,
      GAMES_TYPES.all,
      appendFetchedGames,
    );
  } finally {
    loadingAllGames.value = false;
  }
};
const { loadGamesSSR: loadAllGamesForSSR } =
  useFetchGamesSSR(GAMES_TYPES.all, allGamesChunkSize.value + allGamesChunkSize.value);
const isNewGamesLazyLoading = ref(false);
const isAllGamesLazyLoading = ref(false);
const onResize = async () => {
  if (currentPlatform.value !== previousPlatform.value) { // Check for platform change
    previousPlatform.value = currentPlatform.value; // Update previous platform
    // Perform actions when platform changes
    isNewGamesLazyLoading.value = true;
    isAllGamesLazyLoading.value = true;
    fetchBannersData(currentPlatform.value); // Example action, adjust as needed
    loadNewGames(0, true); // Reset and load games for the new platform
    isNewGamesLazyLoading.value = false;
    fetchPromoTilesData(currentPlatform.value);
    loadAllGames(0, true); // Reset and load all games for the new platform
    isAllGamesLazyLoading.value = false;
  }
};

onMounted(() => {
  if (!getIsSeoCrawlerDetected.value) {
    nuxtApp.hook('page:finish', () => {
      isNewGamesLazyLoading.value = true;
      isAllGamesLazyLoading.value = true;
    });
    nextTick(async () => {
      await fetchBannersData(currentPlatform.value);
      await loadNewGames(0, true);
      isNewGamesLazyLoading.value = false;
      await fetchPromoTilesData(currentPlatform.value);
      await loadAllGames(0, true);
      isAllGamesLazyLoading.value = false;
    });
  }
});

/**
 * Fetches banners, promo tiles, games, news data when component is server-side rendered.
 */
 await useAsyncData(
  'fetch-homepage-data',
  async () => {
    setPageLoader(true);

    if (getIsSeoCrawlerDetected.value) {
      await fetchBannersDataForSSR(PLATFORM.desktop);
      await loadNewGamesForSSR();
      await fetchPromoTilesDataForSSR(PLATFORM.desktop);
      await loadAllGamesForSSR();
    }

    await fetchNewsDataForSSR();
    setPageLoader(false);

    return {};
  },
  { server: true },
);
</script>
